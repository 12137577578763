import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue'; // Adjust the path if necessary
import AdminPanel from '@/components/AdminPanel.vue'; // Adjust the path if necessary
import Term from '@/components/Term.vue'; // Adjust the path as necessary
import FAQ from '@/components/FAQ.vue'; // Adjust the path as necessary
import About from '@/components/About.vue'; // Adjust the path as necessary
import A2Z from '@/components/A2Z.vue'; // Adjust the path as necessary


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'BJJ Dictionary'
    }
  },  
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPanel,
    meta: {
      title: 'BJJ Dictionary - Admin'
    }
  },
  {
    path: '/term/:id',
    name: 'Term',
    component: Term,
    meta: {
      title: (route) => {
        const formattedId = route.params.id
          .replace(/-/g, ' ') // Replace dashes with spaces
          .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
        return `BJJ Dictionary - ${formattedId}`;
      }
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      title: 'BJJ Dictionary - FAQ'
    }
  },  
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'BJJ Dictionary - About'
    }
  },     
  {
    path: '/a-z',
    name: 'A2Z',
    component: A2Z,
    meta: {
      title: 'BJJ Dictionary - A to Z'
    }
  },          
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, savedPosition) {
    // Check if the route you're navigating to is the one for term.vue
    if (to.name === 'Term') {
      // Always scroll to the top when navigating to term.vue
      return { top: 0 };
    }

    // For other routes, preserve the saved scroll position
    if (savedPosition) {
      return savedPosition;
    } else {
      // Default scroll behavior for other routes
      return { top: 0 };
    }
  }  
});

router.beforeEach((to, from, next) => {
  const title = typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title;
  document.title = title || 'Default Title';
  next();
});

export default router;

