// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCF9l-Wn7svWbzYiyoMWld8JYmBSoGq0sU",
  authDomain: "bjj-dictionary-1c051.firebaseapp.com",
  projectId: "bjj-dictionary-1c051",
  storageBucket: "bjj-dictionary-1c051.appspot.com",
  messagingSenderId: "35919702023",
  appId: "1:35919702023:web:4a81c58a6ba1a2ea4122a2",
  measurementId: "G-Q29H1GG792"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// Firestore database
const db = getFirestore(app);

// Enable AppCheck debugging
//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// Initialize AppCheck with ReCaptchaEnterpriseProvider
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lera0MqAAAAAJXIF_YncAl38aBTF7H3F3i7rO54'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});


//const auth = getAuth(app);

export { db, analytics, appCheck };