<template>
    <v-container>
        <v-app-bar scroll-behavior="hide" scroll-threshold="30" color="primary" dark>
            <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>    
            <div v-if="!showSearch" @click="$router.push('/')" class="pt-1 pl-2" style="cursor: pointer;"><img src="@/assets/logo2.svg" class="responsive-text pt-1"></div>
            <v-spacer></v-spacer>
            <v-autocomplete
                v-if="showSearch"
                class="mt-0"
                prepend-inner-icon="mdi-magnify"
                density="comfortable"
                ref="autocomplete" 
                v-model="searchQuery"
                :items="filteredTerms"
                variant="solo"
                style="max-width: 745px; min-width: 330px;"
                item-title="term"
                item-value="name"
                @update:modelValue="navigateToTerm"
                clear-icon="mdi-close-circle" 
                clearable 
                hide-details
                placeholder="Search..."
                menu-icon=""
            ></v-autocomplete>   
            <v-spacer></v-spacer>
            <v-btn icon @click="showSearch = !showSearch">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>            
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" floating temporary>
            <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-view-dashboard" title="Home" value="home" @click="$router.push('/')"></v-list-item>
            <v-list-item prepend-icon="mdi-order-alphabetical-ascending" title="List" value="home" @click="$router.push('/a-z')"></v-list-item>
            <v-list-item prepend-icon="mdi-arrow-decision" title="Random" value="random" @click="redirectToRandomTerm()"></v-list-item>
            <v-list-item prepend-icon="mdi-frequently-asked-questions" title="FAQ" value="faq" @click="$router.push('/faq')"></v-list-item>
            <v-list-item prepend-icon="mdi-hand-heart-outline" title="Donate" value="donate"  href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS" target="_blank"></v-list-item>
            </v-list>
      </v-navigation-drawer>        

      

        <v-row justify="center" >
            <v-col cols="12" style="max-width: 1000px;" class="pb-0">
                <span @click="test()" class="title-font">{{ term.term }}</span>
                <v-btn v-if="term.ttsLink" icon="mdi-volume-high" class="ml-2 mb-2" @click="playAudio" variant="text"></v-btn>
                <audio ref="audioPlayer" style="display: none;"></audio>
            </v-col>
        </v-row>

      <v-row justify="center" class="mt-0">
        <v-col cols="12" style="max-width: 1000px;">
            <v-divider></v-divider>
            <v-breadcrumbs divider="" class="pt-2 pb-2">
                <v-breadcrumbs-item
                    v-for="(item, index) in filteredBcitems"
                    :key="index"
                    :disabled="item.disabled"
                    @click="updatePanels(index)"
                    class="breadcrumb-item"
                >
                    {{ item.title }}
                </v-breadcrumbs-item>                
            </v-breadcrumbs>
            <v-divider></v-divider>            
        </v-col>
      </v-row>

      <v-row justify="center" v-if="alert">
        <v-col cols="12" class="d-flex justify-center">            
            <v-alert      
            v-model="alert"
            icon="mdi-hand-heart-outline"
            border="start"      
            color="#15ba22"
            variant="tonal"
            max-width="500px"  
            class="text-subtitle-2"          
            closable
            >
            Our website is proudly supported solely by <a href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS" class="custom-link" target="_blank">donations</a>. If you find our resources helpful, please consider making a donation to help us continue providing valuable content. Every <a href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS" class="custom-link" target="_blank">contribution</a> counts!
            </v-alert>

        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" style="max-width: 1000px;">

            <v-expansion-panels v-model="panels" multiple variant="accordion">

            <v-expansion-panel>
                <v-expansion-panel-title class="expansion-font">Overview</v-expansion-panel-title>
                <v-expansion-panel-text class="def-font link-style"><p v-html="term.definition"></p></v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel v-if="term.keydetails">
                <v-expansion-panel-title class="expansion-font">Key Details</v-expansion-panel-title>
                <v-expansion-panel-text><div class="def-font ql-editor pa-0" id="kd-vhtml" v-html="linkifiedKeyDetails"></div></v-expansion-panel-text>
            </v-expansion-panel>     
            
            <v-expansion-panel v-if="term.video">
                <v-expansion-panel-title class="expansion-font">Example</v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; background: #000;">
                        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" :src="'https://www.youtube.com/embed/' + term.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>              

            </v-expansion-panels>                        

        </v-col>
      </v-row>

    </v-container>

  </template>

<script>
import { useMainStore } from '@/stores/mainStore';
import { useRoute } from 'vue-router';

export default {
  name: 'Term',
  data() {
	return {
      alert: true,
      drawer: false,
      loading: true,
      showSearch: false,
	  store: useMainStore(),
	  route: useRoute(),
      searchQuery: '',
      panels: [0, 1, 2],  
      bcitems: [
        {
          title: 'Overview',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          title: 'Key Details',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          title: 'Example',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },        

      ],
      dictionary: {
        "Brazilian Jiu-Jitsu": "/terms/brazilian-jiu-jitsu",
        "Guard": "/terms/guard",
        "Submission": "/terms/submission",
        },      
	  };
  },
  async created() {
    /*
    if (Object.keys(this.store.terms).length === 0) {
      await this.store.fetchTerms();
    }
      */
  },
  computed: {
	term() {
        const termId = this.route.params.id;
        console.log(termId);
        return this.store.terms[termId];
	},
    filteredTerms() {
        const query = (this.searchQuery ?? '').toLowerCase();
        return Object.entries(this.store.terms)
            .map(([key, term]) => ({ ...term, name: key }))
            .filter(term =>
                term.term.toLowerCase().includes(query) && term.enabled !== false
            )
            .sort((a, b) => a.term.localeCompare(b.term)); 
    },    
    filteredBcitems() {
      return this.bcitems.filter((item, index) => {
        if (index === 2) {
            return this.term.video !== undefined && this.term.example !== '';
        }
        if (index === 1) {
            return this.term.keydetails !== undefined && this.term.example !== '';
        }        
        return true;
      });
    },
    linkifiedKeyDetails() {
      return this.processHTML(this.term.keydetails);
    },  
  },
  methods: {
    redirectToRandomTerm() {
      const store = useMainStore();
      store.redirectToRandomTerm(this.$router);
    },    
    navigateToTerm(termId) {
        console.log("clicked: ", termId);
      if (termId) {
        this.$router.push(`/term/${termId}`);
      }
      this.$refs.autocomplete.blur();
      this.searchQuery = '';
    },
    async playAudio2() {
      const text = this.term.term;
      const apiKey = 'AIzaSyAYa50tWimWqtYUwrlMO1EJQguVlLSm9Qo';
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

      const requestBody = {
        input: { text: text },
        voice: { languageCode: 'en-US', ssmlGender: 'NEUTRAL' },
        audioConfig: { audioEncoding: 'MP3' },
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        const audioContent = data.audioContent;
        const audioPlayer = this.$refs.audioPlayer;
        audioPlayer.src = `data:audio/mp3;base64,${audioContent}`;
        audioPlayer.style.display = 'block';
        audioPlayer.play();
      } catch (error) {
        console.error('Error:', error);
      }
    },    
    async playAudio() {
        const ttsLink = this.term.ttsLink;
        const audioPlayer = this.$refs.audioPlayer;
        
        try {
            audioPlayer.src = ttsLink;
            audioPlayer.style.display = 'block';
            audioPlayer.play();
        } catch (error) {
            console.error('Error:', error);
        }
    },
    updatePanels(index) {
        this.panels = [index];
    },    
    capitalizeWords(str) {
      return str.replace(/\b\w/g, char => char.toUpperCase());
    },
    linkifyText(text, termsMap, linkedTerms) {
        // Create a single regex pattern for all terms
        const termsPattern = Object.keys(termsMap).join('|');
        const regex = new RegExp(`\\b(${termsPattern})\\b`, 'gi');

        // Replace terms in the text
        text = text.replace(regex, (match) => {
            const normalizedTerm = match.toLowerCase(); // Normalize the matched term to lowercase
            if (!linkedTerms.has(normalizedTerm)) { // If this term hasn't been linked yet
                linkedTerms.add(normalizedTerm); // Mark it as linked
                return `<a href="${termsMap[normalizedTerm]}">${match}</a>`; // Link the first occurrence
            }
            return match; // Otherwise, return the match without linking it
        });

        return text; // Return the processed text with links added
    },
    processHTML(htmlContent) {

        // Create a DOMParser to parse the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");

        // Define elements to exclude from link processing
        const excludedTags = ["H1", "H2", "H3", "BLOCKQUOTE", "CODE", "PRE", "EM", "STRONG"];

        // Create a map of terms and their links from this.store.terms
        const termsMap = Object.keys(this.store.terms)
        .filter(key => this.store.terms[key].term !== this.term.term && this.store.terms[key].enabled !== false)
        .reduce((map, key) => {
          const termObj = this.store.terms[key];
          map[termObj.term.toLowerCase()] = termObj.name;
          return map;
        }, {});

        // Set to keep track of linked terms
        const linkedTerms = new Set();

        // Traverse the nodes and process text nodes
        const traverseNodes = (node) => {
            // Skip nodes inside excluded tags
            if (excludedTags.includes(node.nodeName)) {
                return;
            }

            // If the node is a text node, process it
            if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim().length > 0) {
                const linkedText = this.linkifyText(node.nodeValue, termsMap, linkedTerms);
                const newSpan = document.createElement('span');
                newSpan.innerHTML = linkedText;
                node.replaceWith(...newSpan.childNodes); // Replace the text node with the new linked HTML
            } else {
                // Recursively process child nodes
                node.childNodes.forEach(childNode => traverseNodes(childNode));
            }
        };

        // Start traversing from the body of the parsed document
        traverseNodes(doc.body);

        // Return the processed HTML content
        return doc.body.innerHTML;
    },
  test(){
    console.log(this.processHTML(this.term.keydetails))
  }            
  },  
  async mounted() {
    //const store = useMainStore();
    //await store.fetchTerms(); // Adjust according to your store action
    //this.loading = false;
    //document.title = this.term.term ? `BJJ Dictionary - ${this.capitalizeWords(this.term.term)}` : 'BJJ Dictionary';
  },  
};
</script>

<style scoped>
@import '../styles/TermStyles.css';

.main-container {
  background-color: #f9f9f9;
}
.title-font {
  font-family: 'Linux Libertine', 'Georgia', 'Times', 'Source Serif Pro', serif;
  font-size: 1.8em;
  font-weight: 400;
}

.expansion-font {
  font-family: 'Linux Libertine', 'Georgia', 'Times', 'Source Serif Pro', serif;
  font-size: 1.5em;
  font-weight: 400;
} 

.def-font {
    font-family: sans-serif;
    font-size: 1.0em;
    line-height: 1.6;
}

.breadcrumb-item {
    font-family: sans-serif;
    cursor: pointer;
    color: #1e90ff; /* Ensure it inherits the default color */
    font-size: 0.97em;
    font-weight: 400;
    margin-right: 10px;
}

.breadcrumb-item:hover {
    color: #0755ff; /* Change this to your desired hover color */
}

.responsive-text {
  width: 220px;

}

.custom-link {
  color: rgb(0, 104, 0);
  text-decoration: none;
}

.ql-editor :deep(a){
  color: #3365cc;
  text-decoration: none;
}

.ql-editor :deep(a:hover) {
  text-decoration: underline;
}

.link-style :deep(a){
  color: #3365cc;
  text-decoration: none;
}

.link-style :deep(a:hover) {
  text-decoration: underline;
}

.custom-link:hover {
  text-decoration: underline;
}

</style>