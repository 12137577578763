import { defineStore } from 'pinia';
import { collection, getDocs, getFirestore, doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
//import { useRouter } from 'vue-router';

export const useMainStore = defineStore('main', {
  state: () => ({
	// Define your state properties here
	counter: 0,
    terms: {}, 
    termsLoaded: false,
    lastUpdated: null,
    progress: 0,
    snackbar: {
      show: false,
      message: '',
      timeout: 3000, // Duration in milliseconds
    },    
  }),
  actions: {
	// Define your actions here
	increment() {
	  this.counter++;
	},
    async fetchTerms() {
      //last updated code
      const lastUpdateRef = doc(db, 'config', 'lastUpdate');
      const lastUpdateDoc = await getDoc(lastUpdateRef);
      if (lastUpdateDoc.exists()) {     
        const lastUpdateTime = lastUpdateDoc.data().time;   
        console.log(`Last update timestamp: `, lastUpdateDoc.data().time.seconds);
        console.log("Current last updated time: ", this.lastUpdated);

        if (this.lastUpdated && this.lastUpdated.seconds == lastUpdateTime.seconds) {
          console.log('No new updates found.');
          return;
        }
        this.lastUpdated = lastUpdateTime;        

      } else {
        console.log('No such document!');
      }
      //fetch terms code
      console.log("fetch terms")
      const querySnapshot = await getDocs(collection(db, 'terms'));
      const totalDocs = querySnapshot.docs.length;
      let processedDocs = 0;
      
      this.terms = querySnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        processedDocs++;
        this.progress = (processedDocs / totalDocs) * 100; // Update progress percentage
        this.termsLoaded = processedDocs === totalDocs; // Set termsLoaded to true when all docs are processed
        return acc;
      }, {});
      },    
    async saveTerm(selectedTerm) {
      if (!selectedTerm || !selectedTerm.name) {
        console.error('selectedTerm is undefined or does not have a name property');
        return;
      }      
        const db = getFirestore();
        const termRef = doc(db, 'terms', selectedTerm.name);
        await setDoc(termRef, selectedTerm);
        console.log(`Term ${selectedTerm.name} has been saved successfully.`);

        // Set lastUpdate.time to the current timestamp
        const configRef = doc(db, 'config', 'lastUpdate');
        await setDoc(configRef, { time: Timestamp.now() }, { merge: true });
        console.log('Last update time has been set to the current timestamp.');

        //alert(`Term ${selectedTerm.name} has been saved successfully.`);
        this.showSnackbar(`Term ${selectedTerm.name} has been saved successfully.`, 'success') 
      },
    async addNewTerm(termName, termDoc) {
        termName = termName.toLowerCase();
        termDoc = termDoc.toLowerCase();      

        try {
          const docRef = doc(collection(db, 'terms'), termDoc);
          await setDoc(docRef, {
            term: termName,
            enabled: false,
          });

          // Set lastUpdate.time to the current timestamp
          const configRef = doc(db, 'config', 'lastUpdate');
          await setDoc(configRef, { time: Timestamp.now() }, { merge: true });
          console.log('Last update time has been set to the current timestamp.');

          //alert('New term added successfully');
          this.showSnackbar(`New term added successfully.`, 'success')           
          this.fetchTerms();
        } catch (error) {
          console.error('Error adding document: ', error);
          //alert('Failed to add new term');
          this.showSnackbar(`Failed to add new term`, 'red')           
          
        }
      },      
      showSnackbar(message, color) {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      },        
      redirectToRandomTerm(router) {
       // const router = useRouter();
        //const termKeys = Object.keys(this.terms);
        const termKeys = Object.entries(this.terms)
        .filter(([, term]) => term.enabled)
        .map(([key]) => key);

        if (termKeys.length === 0) {
          console.error('No terms available to select.');
          return;
        }
        const randomKey = termKeys[Math.floor(Math.random() * termKeys.length)];
        //this.$router.push({ name: 'Term', params: { id: randomKey } });
        router.push(`/term/${randomKey}`);
      }      
  },
  persist: true,

});
